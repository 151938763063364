@import url('https://battle-of-angelus.ovh/fonts/Pirata-One/Pirata-One-font.css');
@import url('https://battle-of-angelus.ovh/fonts/MedievalSharp/MedievalSharp-font.css');


/*index.js*/
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}






/*******************************************************************************/
/* App.js */
.App {
  font-family: 'Pirata One', cursive;
  text-align: center;
  color: #D4AF37;
}

h1{
  /*font-family: 'Pirata One', cursive;*/
  font-family: 'MedievalSharp', cursive;
  margin-top: 3rem;
}

ul {
  list-style-type: none;
  padding: 0;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/*******************************************************************************/

.App-header {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: calc(0.625rem + 2vmin); /* calc(10px + 2vmin) */
  color: #D4AF37;;
  background-image: url('https://battle-of-angelus.ovh/images/wallpaper.png');
}

.text-background {
  position: relative; /* Assure que l'enfant utilise cette position pour se positionner */
  background-color: rgba(0, 0, 0, 0.6);
  padding: 1.25rem; /* 20px */
  padding-left: 2.25rem;
  padding-right: 2.25rem;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  
  border-radius: 0.625rem; /* 10px */
  text-align: center;
  margin-top: 8rem;
  min-height: 60rem;
}
/*******************************************************************************/

button {
  background-color: #2C2C2C; /* Couleur de fond sombre, métallique */
  border: 0.2rem solid #5C4033; /* Bordure couleur marron pour rappeler le cuir ou le métal vieilli */
  
  color: #D4AF37; /* Couleur dorée pour le texte */
  cursor: pointer;
  font-size: 1rem;
  font-family: 'Pirata One', serif; /* Assure-toi que la police est bien chargée */
  border-radius: 0.3rem; /* Légère courbure pour un aspect moins rigide */
  box-shadow: 0 0.3rem 0.5rem rgba(0, 0, 0, 0.5); /* Ombre douce pour donner du relief */
  transition: background-color 0.3s, box-shadow 0.3s; /* Transition fluide pour l'effet de survol */
}

button:hover {
  background-color: #3F3F3F; /* Légère variation de la couleur de fond au survol */
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.7); /* Ombre plus prononcée pour l'effet de survol */
  color: #FFFFFF; /* Texte devient blanc pour plus de contraste au survol */
}


.nft-navigation button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
} 

/*******************************************************************************/
/* NavBar */
.navbar {
  background-color: rgba(0, 0, 0, 0.6);
  /*padding: 10px 20px;*/
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 1rem;
  right: 1rem;
  height: 2rem;
  z-index: 1000;
}

.buy-button{
  background-color: #2C2C2C; /* Couleur de fond sombre, métallique */
  border: 0.2rem solid #5C4033; /* Bordure couleur marron pour rappeler le cuir ou le métal vieilli */
  
  color: #D4AF37; /* Couleur dorée pour le texte */
  cursor: pointer;
  font-size: 1rem;
  font-family: 'Pirata One', serif; /* Assure-toi que la police est bien chargée */
  border-radius: 0.3rem; /* Légère courbure pour un aspect moins rigide */
  box-shadow: 0 0.3rem 0.5rem rgba(0, 0, 0, 0.5); /* Ombre douce pour donner du relief */
  transition: background-color 0.3s, box-shadow 0.3s; /* Transition fluide pour l'effet de survol */
}

.buy-button:hover {
  background-color: #3F3F3F; /* Légère variation de la couleur de fond au survol */
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.7); /* Ombre plus prononcée pour l'effet de survol */
  color: #FFFFFF; /* Texte devient blanc pour plus de contraste au survol */
}

.navbar-content {
  display: flex;
  justify-content: flex-start; /* Aligner tous les éléments à gauche */
  align-items: center; /* Centre verticalement les éléments */
  width: 100%;
}

.token-info,
.nft-info,
.account-info {
  margin-right: 1rem; /* Espace entre les sections */
  display: flex; /* Aligne le contenu en ligne */
  align-items: center; /* Centre verticalement le contenu à l'intérieur */
}

.account-info button{
  margin-top: 0px;
  padding: 0.2rem 1rem;
}


/* Ajustez la taille des boutons pour qu'ils correspondent */
.token-info button,
.nft-info button,
.account-info button {
  margin-left: 1rem; /* Espace entre le texte et le bouton */
}



.tooltip {
  position: absolute;
  background: rgba(255, 255, 255, 0.9);
  padding: 0.1rem;
  border-radius: 0.313rem;
  color: black;
  font-size: 0.7rem;
  z-index: 1000; /* Assurez-vous qu'il soit au-dessus */
  top: -1rem; /* Ajustez la position selon le besoin */
}


.account-popup {
  position: absolute; /* Positionne le popup par rapport à l'élément parent */
  top: 4rem; /* Ajustez la position selon vos besoins */
  right: 1rem; /* Positionne à droite */
  background-color: rgba(0, 0, 0, 0.8);
  padding: 0; /* Espacement interne */
  font-size: 0.8rem;
  border-radius: 0.3rem; /* Coins arrondis */
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.5); /* Ombre pour le popup */
  z-index: 1000; /* Assurez-vous qu'il soit au-dessus */
}

.person-icon{
  margin-bottom: -0.3rem;
  margin-right: .5rem;
  width: 1.2em;
}


.crypto-icon{
  margin-bottom: -0.3rem;
  margin-right: .5rem;
  width: 1.2em;
}


/*******************************************************************************/



/* NFT Liste */
.nft-display {
  overflow-x: auto;
  white-space: nowrap;
  margin-bottom: 1.25em; /* 20px */
}


.nft-container {
  display: inline-flex;
}

.nft-card {
  display: inline-block;
  margin-right: 1.25em; /* 20px */
  text-align: center;
 
  padding: 0.625em; /* 10px */


  border: 0.125em solid #d3913c; /* 2px */
  border-radius: 0.3125em; /* 5px */
  background: rgba(255, 255, 255, 0.2)

}

.nft-display .nft-container .nft-card h4 {
  font-family: MedievalSharp, cursive;
  margin: 0;
  padding: 0;
  max-width: 100%;   /* Ajuste la largeur maximale pour forcer un retour à la ligne */
  word-wrap: break-word;  /* Autorise le texte à passer à la ligne si nécessaire */
  overflow-wrap: break-word; /* Alternative pour forcer le retour à la ligne */
  white-space: normal; /* Assure que le texte ne reste pas sur une seule ligne */
}

.nft-display .nft-container .nft-card p{
  margin: 0;
  padding: 0;
}

.selected-nft {
  border: 0.3em solid #4caf50; /* Cadre vert pour le personnage sélectionné */
  box-shadow: 0 0 10px rgba(0, 255, 0, 0.5); /* Ombre verte pour souligner la sélection */
}


.nft-card .combat-timer {
  position: relative; /* Nécessaire pour positionner le h4 par rapport à la barre de progression */
}

.nft-card .combat-timer h4 {
  position: absolute;
  width: 100%; 
  top: 0; 
  left: 50%; 
  transform: translateX(-50%); 
  text-align: center;
  font-size: 0.9rem;
  line-height: 20px;
  z-index: 2; 
  color: #D4AF37;; 
}

.nft-card .combat-timer .progress-bar-container {
  position: relative;
  width: 100%;
  background-color: #e0e0e0;
  height: 15px; /* Réduire un peu la hauteur pour correspondre à la vue en liste */
  border-radius: 8px;
  overflow: hidden;
  height: 4rem;
}

.nft-card .combat-timer .progress-bar-container .progress-bar {
  background-color: #4caf50;
  height: 100%;
  transition: width 0.5s ease-in-out;
  border-radius: 8px;
  position: relative;
  z-index: 1;
}
/*******************************************************************************/
/* CreateCharacter.js */

.bp_generate_perso{
  margin-top: 1rem;
  font-size: 2rem;
}

.modal-container-create {
  position: absolute;
  top: 3.125rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  
  color: #D4AF37;
  padding: 1rem;
  max-width: 600px; /* Limite la largeur maximale */
  width: auto; /* Ajuste la largeur automatiquement */
  border: 0.125em solid #d3913c; /* 2px */
  border-radius: 0.3125em; /* 5px */
  background: rgb(113, 112, 99, 0.8);
}

canvas{
  align-content: center;
}


.modal-container-create .card-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1.25rem 0;
  width: 100%; /* La carte occupe 100% de la largeur disponible */
  perspective: 1000px; /* Nécessaire pour l'effet de flip */
}




 
.modal-container-create .card-container .card {
  width: 100%;
  max-width: 600px; /* Limiter la taille de la carte */
  height: 18.5em;/* Ajuste la hauteur automatiquement en fonction du contenu */
  position: relative;
  transition: transform 0.6s;
  transform-style: preserve-3d; /* Nécessaire pour l'effet de flip */
  min-width: 20rem;
  min-height: 27rem;
}
  
 
.modal-container-create .card-container .card .card-front,
.modal-container-create .card-container .card .card-back {
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
  backface-visibility: hidden; 
}


.modal-container-create .card-container .card .card-back {
  transform: rotateY(180deg); /* Retourne la face arrière */
}

.modal-container-create .card-container .card .card-front img, 
.modal-container-create .card-container .card .card-back img {
  width: 100%;
  height: 18.5em;
  min-height: 27rem;
}

.modal-container-create .card-container .card.flipped {
  transform: rotateY(180deg); /* L'effet de flip pour retourner la carte */
}


.modal-container-create .bp_create_character {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 1.25rem;
}

.modal-container-create .bp_create_character .bp_create_mint {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
}

.modal-container-create .bp_create_character .bp_create_mint button {
  margin: 0.625rem 0;
}


/* information recto */
.modal-container-create .card-container .card .card-front .character-info {
  flex-grow: 1; /* Permet de prendre tout l'espace disponible */
  display: flex;
  flex-direction: column;
  justify-content: center; /* Centrer verticalement */
  position: absolute;
  
  top: 0;
  left: 0;
  width: 100%;
}


.modal-container-create .card-container .card .card-front .character-info .character-header{
  display: flex;
  flex-direction: column;
  color: #D4AF37;;
  width: fit-content;
  align-self: center;
  background-color: #0009;
  border-radius: 0.625rem; /* 10px */
  background-color: #2C2C2C; /* Couleur de fond sombre, métallique */
  border: 0.2rem solid #5C4033; /* Bordure couleur marron pour rappeler le cuir ou le métal vieilli */
  
  color: #D4AF37; /* Couleur dorée pour le texte */
}
  

.modal-container-create .card-container .card .card-front .character-info .character-body{
  display: flex;
  flex-direction: row;
  font-size: 1.1rem;
  align-self: center;
}
 
 
.modal-container-create .card-container .card .card-front .character-info .character-footer{
  background-color: #0009;
  bottom: 0;
  padding: 10px;
  position: fixed;
  text-align: center;
  width: fit-content;
  align-self: center;
  border-radius: 0.625rem; /* 10px */
  background-color: #2C2C2C; /* Couleur de fond sombre, métallique */
  border: 0.2rem solid #5C4033; /* Bordure couleur marron pour rappeler le cuir ou le métal vieilli */
  
  color: #D4AF37; /* Couleur dorée pour le texte */
} 

.modal-container-create .card-container .card .card-front .character-info .character-header .character-name{
  font-family: 'MedievalSharp', cursive;
  font-size: 2rem;
  font-weight: bolder;
  margin: 0;
}

.modal-container-create .card-container .card .card-front .character-info .character-header .character-gender{

  font-size: 1.5rem;
  font-weight: bolder;
  margin: 0;
  
}

.modal-container-create .card-container .card .card-front .character-info .character-body .character-stats-left {
  margin-right: 11em;
  margin-top: 2rem;
  font-size: 1rem;
  width: auto;
  background-color: #0009;
  height: fit-content;
  border-radius: 0.625rem; /* 10px */
  background-color: #2C2C2C; /* Couleur de fond sombre, métallique */
  border: 0.2rem solid #5C4033; /* Bordure couleur marron pour rappeler le cuir ou le métal vieilli */
  
  color: #D4AF37; /* Couleur dorée pour le texte */
  padding-left: 1rem;
  padding-right: 1rem;
}

  
  
  
  
  

.modal-container-create .card-container .card .card-front .character-info .character-body .character-stats-right {
  font-size: 1rem;
  margin-top: 2rem;
  color: #D4AF37;;
  width: auto;
  background-color: #0009;
  height: fit-content;
  border-radius: 0.625rem; /* 10px */
  background-color: #2C2C2C; /* Couleur de fond sombre, métallique */
  border: 0.2rem solid #5C4033; /* Bordure couleur marron pour rappeler le cuir ou le métal vieilli */
  
  color: #D4AF37; /* Couleur dorée pour le texte */
}

.modal-container-create .card-container .card .card-front .character-info .character-footer .character-race{
  font-size: 1.5rem; /* Ajustez la taille si nécessaire */
  margin: 0;
  padding: 0;
  color: #D4AF37;; /* Pour bien contraster avec l'image de fond */

}

.modal-container-create .card-container .card .card-front .character-info .character-footer .character-class{
  font-size: 1.5rem; /* Ajustez la taille si nécessaire */
  margin: 0;
  padding: 0;
  color: #D4AF37;; /* Pour bien contraster avec l'image de fond */
}


/* Verso */
.modal-container-create .card-container .card .card-back .abilities-info {
  flex-grow: 1; /* Permet de prendre tout l'espace disponible */
  display: flex;
  flex-direction: column;
  justify-content: center; /* Centrer verticalement */
  position: absolute;
  
  top: 0;
  left: 0;
  width: 100%;
  border-radius: 0.625rem; /* 10px */
  background-color: #2c2c2cbd; /* Couleur de fond sombre, métallique */
  border: 0.2rem solid #5C4033; /* Bordure couleur marron pour rappeler le cuir ou le métal vieilli */
  
  color: #D4AF37; /* Couleur dorée pour le texte */
  margin-top: 1rem;
  min-height: 27rem;
}

.modal-container-create .card-container .card .card-back .abilities-info .abilities-info-raciale{
  
  height: fit-content;
 
  font-size: 1rem;
}

.modal-container-create .card-container .card .card-back .abilities-info .abilities-info-raciale h4{
  
  font-size: 1.5rem;
  margin: 0;
  padding: 0;
}

.modal-container-create .card-container .card .card-back .abilities-info .abilities-info-class{
  
  height: fit-content;
  
  font-size: 1rem;
}

.modal-container-create .card-container .card .card-back .abilities-info .abilities-info-class h4{
  
  font-size: 1.5rem;
  margin: 0;
  padding: 0;
}

.modal-container-create .card-container .card .card-back .abilities-info .abilities-info-class .abilities-info-class-attack{
  margin: 0;
  padding: 0;
  font-size: 1rem;
  margin-bottom: 1rem;
}

.modal-container-create .card-container .card .card-back .abilities-info .abilities-info-class .abilities-info-class-attack h5{
  margin: 0;
  padding: 0;
  font-size: 1.2rem;
}

.modal-container-create .card-container .card .card-back .abilities-info .abilities-info-class .abilities-info-class-attack ul{
  margin: 0;
  padding: 0;
  font-size: 1rem;
}


.modal-container-create .card-container .card .card-back .abilities-info .abilities-info-class .abilities-info-class-defense{
  margin: 0;
  padding: 0;
  font-size: 1rem;
  margin-bottom: 1rem;
}

.modal-container-create .card-container .card .card-back .abilities-info .abilities-info-class .abilities-info-class-defense h5{
  margin: 0;
  padding: 0;
  font-size: 1.2rem;
}

.modal-container-create .card-container .card .card-back .abilities-info .abilities-info-class .abilities-info-class-defense ul{
  margin: 0;
  padding: 0;
  font-size: 1rem;
}


.modal-container-create .card-container .card .card-back .abilities-info .abilities-info-class .abilities-info-class-heal{
  margin: 0;
  padding: 0;
  font-size: 1rem;
  margin-bottom: 1rem;
}

.modal-container-create .card-container .card .card-back .abilities-info .abilities-info-class .abilities-info-class-heal h5{
  margin: 0;
  padding: 0;
  font-size: 1.2rem;
}

.modal-container-create .card-container .card .card-back .abilities-info .abilities-info-class .abilities-info-class-heal ul{
  margin: 0;
  padding: 0;
  font-size: 1rem;
}


/*******************************************************************************/
/* DetailCharacter.js */

.modal-container-detail {
  position: absolute;
  top: 3.125rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  
  color: #D4AF37;;
  padding: 1rem;
  max-width: 600px; /* Limite la largeur maximale */
  width: auto; /* Ajuste la largeur automatiquement */
  border: 0.125em solid #d3913c; /* 2px */
  border-radius: 0.3125em; /* 5px */
  background: rgb(113, 112, 99, 0.8);
}

.modal-container-detail .card-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1.25rem 0;
  width: 100%; /* La carte occupe 100% de la largeur disponible */
  perspective: 1000px; /* Nécessaire pour l'effet de flip */
}


.modal-container-detail .card-container .card {
  width: 100%;
  max-width: 600px; /* Limiter la taille de la carte */
  height: 18.5em;/* Ajuste la hauteur automatiquement en fonction du contenu */
  position: relative;
  transition: transform 0.6s;
  transform-style: preserve-3d; /* Nécessaire pour l'effet de flip */
  min-width: 20rem;
  min-height: 27rem;
}
  
 
.modal-container-detail .card-container .card .card-front,
.modal-container-detail .card-container .card .card-back {
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
  backface-visibility: hidden; 
}


.modal-container-detail .card-container .card .card-back {
  transform: rotateY(180deg); /* Retourne la face arrière */
}

.modal-container-detail .card-container .card .card-front img, 
.modal-container-detail .card-container .card .card-back img {
  width: 100%;
  height: 18.5em;
  min-height: 27rem;
}

.modal-container-detail .card-container .card.flipped {
  transform: rotateY(180deg); /* L'effet de flip pour retourner la carte */
}

/* information recto */
.modal-container-detail .card-container .card .card-front .character-info {
  flex-grow: 1; /* Permet de prendre tout l'espace disponible */
  display: flex;
  flex-direction: column;
  justify-content: center; /* Centrer verticalement */
  position: absolute;
  
  top: 0;
  left: 0;
  width: 100%;
}

.modal-container-detail .card-container .card .card-front .character-info .character-header{
  display: flex;
  flex-direction: column;
  color: #D4AF37;;
  width: fit-content;
  align-self: center;
  background-color: #0009;
  border-radius: 0.625rem; /* 10px */
  background-color: #2C2C2C; /* Couleur de fond sombre, métallique */
  border: 0.2rem solid #5C4033; /* Bordure couleur marron pour rappeler le cuir ou le métal vieilli */
  
  color: #D4AF37; /* Couleur dorée pour le texte */
}
  

.modal-container-detail .card-container .card .card-front .character-info .character-body{
  display: flex;
  flex-direction: row;
  font-size: 1.1rem;
  align-self: center;
}
 
 
.modal-container-detail .card-container .card .card-front .character-info .character-footer{
  background-color: #0009;
  bottom: 0;
  padding: 10px;
  position: fixed;
  text-align: center;
  width: fit-content;
  align-self: center;
  border-radius: 0.625rem; /* 10px */
  background-color: #2C2C2C; /* Couleur de fond sombre, métallique */
  border: 0.2rem solid #5C4033; /* Bordure couleur marron pour rappeler le cuir ou le métal vieilli */
  
  color: #D4AF37; /* Couleur dorée pour le texte */
} 

.modal-container-detail .card-container .card .card-front .character-info .character-header .character-name{
  font-family: 'MedievalSharp', cursive;
  font-size: 2rem;
  font-weight: bolder;
  margin: 0;
}

.modal-container-detail .card-container .card .card-front .character-info .character-header .character-gender{

  font-size: 1.5rem;
  font-weight: bolder;
  margin: 0;
  
}

.modal-container-detail .card-container .card .card-front .character-info .character-body .character-stats-left {
  margin-right: 11em;
  margin-top: 2rem;
  font-size: 1rem;
  width: auto;
  background-color: #0009;
  height: fit-content;
  border-radius: 0.625rem; /* 10px */
  background-color: #2C2C2C; /* Couleur de fond sombre, métallique */
  border: 0.2rem solid #5C4033; /* Bordure couleur marron pour rappeler le cuir ou le métal vieilli */
  
  color: #D4AF37; /* Couleur dorée pour le texte */
  padding-left: 1rem;
  padding-right: 1rem;
}

  
  
  
  
  

.modal-container-detail .card-container .card .card-front .character-info .character-body .character-stats-right {
  font-size: 1rem;
  margin-top: 2rem;
  color: #D4AF37;;
  width: auto;
  background-color: #0009;
  height: fit-content;
  border-radius: 0.625rem; /* 10px */
  background-color: #2C2C2C; /* Couleur de fond sombre, métallique */
  border: 0.2rem solid #5C4033; /* Bordure couleur marron pour rappeler le cuir ou le métal vieilli */
  
  color: #D4AF37; /* Couleur dorée pour le texte */
}

.modal-container-detail .card-container .card .card-front .character-info .character-footer .character-race{
  font-size: 1.5rem; /* Ajustez la taille si nécessaire */
  margin: 0;
  padding: 0;
  color: #D4AF37;; /* Pour bien contraster avec l'image de fond */

}

.modal-container-detail .card-container .card .card-front .character-info .character-footer .character-class{
  font-size: 1.5rem; /* Ajustez la taille si nécessaire */
  margin: 0;
  padding: 0;
  color: #D4AF37;; /* Pour bien contraster avec l'image de fond */
}


/* Verso */
.modal-container-detail .card-container .card .card-back .abilities-info {
  flex-grow: 1; /* Permet de prendre tout l'espace disponible */
  display: flex;
  flex-direction: column;
  justify-content: center; /* Centrer verticalement */
  position: absolute;
  
  top: 0;
  left: 0;
  width: 100%;
  border-radius: 0.625rem; /* 10px */
  background-color: #2c2c2cbd; /* Couleur de fond sombre, métallique */
  border: 0.2rem solid #5C4033; /* Bordure couleur marron pour rappeler le cuir ou le métal vieilli */
  
  color: #D4AF37; /* Couleur dorée pour le texte */
  margin-top: 0.2rem;
  min-height: 27rem;
  max-height: 10em
}

.modal-container-detail .card-container .card .card-back .abilities-info .abilities-info-raciale{
  
  height: fit-content;
 
  font-size: 1rem;
}

.modal-container-detail .card-container .card .card-back .abilities-info .abilities-info-raciale h4{
  
  font-size: 1.5rem;
  margin: 0;
  padding: 0;
}

.modal-container-detail .card-container .card .card-back .abilities-info .abilities-info-class{
  
  height: fit-content;
  
  font-size: 1rem;
}

.modal-container-detail .card-container .card .card-back .abilities-info .abilities-info-class h4{
  
  font-size: 1.5rem;
  margin: 0;
  padding: 0;
}

.modal-container-detail .card-container .card .card-back .abilities-info .abilities-info-class .abilities-info-class-attack{
  margin: 0;
  padding: 0;
  font-size: 1rem;
  margin-bottom: 1rem;
}

.modal-container-detail .card-container .card .card-back .abilities-info .abilities-info-class .abilities-info-class-attack h5{
  margin: 0;
  padding: 0;
  font-size: 1.2rem;
}

.modal-container-detail .card-container .card .card-back .abilities-info .abilities-info-class .abilities-info-class-attack ul{
  margin: 0;
  padding: 0;
  font-size: 1rem;
}


.modal-container-detail .card-container .card .card-back .abilities-info .abilities-info-class .abilities-info-class-defense{
  margin: 0;
  padding: 0;
  font-size: 1rem;
  margin-bottom: 1rem;
}

.modal-container-detail .card-container .card .card-back .abilities-info .abilities-info-class .abilities-info-class-defense h5{
  margin: 0;
  padding: 0;
  font-size: 1.2rem;
}

.modal-container-detail .card-container .card .card-back .abilities-info .abilities-info-class .abilities-info-class-defense ul{
  margin: 0;
  padding: 0;
  font-size: 1rem;
}


.modal-container-detail .card-container .card .card-back .abilities-info .abilities-info-class .abilities-info-class-heal{
  margin: 0;
  padding: 0;
  font-size: 1rem;
  margin-bottom: 1rem;
}

.modal-container-detail .card-container .card .card-back .abilities-info .abilities-info-class .abilities-info-class-heal h5{
  margin: 0;
  padding: 0;
  font-size: 1.2rem;
}

.modal-container-detail .card-container .card .card-back .abilities-info .abilities-info-class .abilities-info-class-heal ul{
  margin: 0;
  padding: 0;
  font-size: 1rem;
}


.modal-container-detail .inventaire-perso {

  border-radius: 0.625rem; /* 10px */
  background-color: #2c2c2cbd; /* Couleur de fond sombre, métallique */
  border: 0.2rem solid #5C4033; /* Bordure couleur marron pour rappeler le cuir ou le métal vieilli */
  
  color: #D4AF37; /* Couleur dorée pour le texte */
  flex-grow: 1; /* Permet de prendre tout l'espace disponible */
  display: flex;
  flex-direction: column;
  justify-content: center; /* Centrer verticalement */
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.modal-container-detail .inventaire-perso .inventaire_container{
  flex-grow: 1; /* Permet de prendre tout l'espace disponible */
  display: flex;
  flex-direction: row;
  justify-content: space-around; /* Centrer verticalement */
  margin-bottom: 0.6rem;
}

.modal-container-detail .inventaire-perso .inventaire_container .armure-perso{
  border-radius: 0.625rem; /* 10px */
  border: 0.2rem solid #5C4033; /* Bordure couleur marron pour rappeler le cuir ou le métal vieilli */
  
  color: #D4AF37; /* Couleur dorée pour le texte */
  height: 9.5rem;
  width: 6.7rem;
  cursor: pointer;
}
.modal-container-detail .inventaire-perso .inventaire_container .armure-perso h5{
  margin: 0;
  padding: 0;
}
 
.modal-container-detail .inventaire-perso .inventaire_container .arme-perso{
  border-radius: 0.625rem; /* 10px */
  border: 0.2rem solid #5C4033; /* Bordure couleur marron pour rappeler le cuir ou le métal vieilli */
  
  color: #D4AF37; /* Couleur dorée pour le texte */
  height: 9.5rem;
  width: 6.7rem;
  cursor: pointer;
}

.modal-container-detail .inventaire-perso .inventaire_container .arme-perso h5{
  margin: 0;
  padding: 0;
}


/* timer detail character*/
.modal-container-detail .combat-timer {
  position: relative; /* Nécessaire pour positionner le h4 par rapport à la barre de progression */
}


.modal-container-detail .combat-timer h4 {
  position: absolute;
  width: 100%; /* Prend toute la largeur de la barre */
  top: 0; /* Se place au début de la barre */
  left: 50%; /* Centre horizontalement le texte */
  transform: translateX(-50%); /* Pour un alignement parfait au centre */
  
  text-align: center; /* Centrer horizontalement */
  font-size: 1rem;
  line-height: 20px; /* Pour centrer verticalement par rapport à la hauteur de la barre */
  z-index: 2; /* Pour s'assurer que le texte soit au-dessus de la barre */
  
  color: #D4AF37;; /* Pour que le texte ressorte bien sur la barre */
  margin: 0;
  padding: 0;
}


.modal-container-detail .combat-timer .progress-bar-container {
  position: relative;
  width: 100%;
  background-color: #e0e0e0;
  height: 20px;
  border-radius: 10px;
  overflow: hidden; /* Assure que la barre de progression reste dans les limites du container */
}


.modal-container-detail .combat-timer .progress-bar-container .progress-bar {
  background-color: #4caf50;
  height: 100%;
  transition: width 0.5s ease-in-out;
  border-radius: 10px;
  position: relative;
  z-index: 1; /* Assure que la barre reste en dessous du texte */
}


/* inventaire */
.modal-container-inventory {
  position: absolute;
  top: 20rem;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  color: #D4AF37;;
  padding: 1rem;
  max-width: 400px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  border: 2px solid #d3913c;
  border-radius: 5px;
  
}

.modal-container-inventory .modal-container-inventory .inventair-container h2 {
  font-family: 'MedievalSharp', cursive;
  text-align: center;
}

.modal-container-inventory .inventair-container .modal-actions {
  display: flex;
  justify-content: space-around;
  margin-top: 1rem;
}


/* Generation Item*/

.modal-container-item {
  position: absolute;
  top: 3.125rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  
  color: #D4AF37;;
  
  max-width: 600px; /* Limite la largeur maximale */
  width: auto; /* Ajuste la largeur automatiquement */
  border: 0.125em solid #d3913c; /* 2px */
  border-radius: 0.3125em; /* 5px */
  background: rgb(113, 112, 99, 1);
  height: 40rem;
}

.modal-container-item .item-card-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1.25rem 0;
  width: 100%; /* La carte occupe 100% de la largeur disponible */
 /* perspective: 1000px; *//* Nécessaire pour l'effet de flip */
 height: 35rem;
}

.modal-container-item .item-card-container .item-card {
  width: 100%;
  height: 35rem;/* Ajuste la hauteur automatiquement en fonction du contenu */
  position: initial;
  
  min-width: 20rem;
  min-height: 27rem;
  
}

.modal-container-item .item-card-container .item-card .item-card-front{
  width: 100%;
  height: 35rem;
  position: absolute;
  top: 0;
  left: 0;
  backface-visibility: hidden;
  
}

.modal-container-item .item-card-container .item-card .item-card-front  img {
  width: 100%;
  height: 35rem;
  min-height: 27rem;
}

.modal-container-item .item-card-container .item-card .item-card-front  .item-info {
  flex-grow: 1; /* Permet de prendre tout l'espace disponible */
  display: flex;
  flex-direction: column;
  justify-content: center; /* Centrer verticalement */
  position: absolute;
  
  top: 0;
  left: 0;
  width: 100%;
}

.modal-container-item .item-card-container .item-card .item-card-front  .item-info .item-header {
  display: flex;
  flex-direction: column;
  color: #D4AF37;;
  width: fit-content;
  align-self: center;
  background-color: #0009;
  border-radius: 0.625rem; /* 10px */
  background-color: #2C2C2C; /* Couleur de fond sombre, métallique */
  border: 0.2rem solid #5C4033; /* Bordure couleur marron pour rappeler le cuir ou le métal vieilli */
  
  color: #D4AF37; /* Couleur dorée pour le texte */
}

.modal-container-item .item-card-container .item-card .item-card-front  .item-info .item-header .item-name-rarity {
  /* Style par défaut */
  font-size: 1.5rem; /* Ajustez selon vos besoins */
}

/* Styles pour les raretés */
.modal-container-item .item-card-container .item-card .item-card-front  .item-info .item-header .item-name-rarity.common {
  color: #d4af37; /* Couleur pour commun (noir) */
}

.modal-container-item .item-card-container .item-card .item-card-front  .item-info .item-header .item-name-rarity.rare {
  color: #007bff; /* Couleur pour rare (bleu) */
  font-weight: bold; /* Plus épais pour plus d'importance */
}

.modal-container-item .item-card-container .item-card .item-card-front  .item-info .item-header .item-name-rarity.epic {
  color: #ff5733; /* Couleur pour épique (orange ou une autre couleur) */
  font-weight: bold; /* Plus épais pour plus d'importance */
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5); /* Ombre pour donner plus d'impact */
}


.modal-container-item .item-card-container .item-card .item-card-front  .item-info .item-header h2{
  padding: 0rem;
  margin-left: 0.4rem;
  margin-right: 0.4rem;
  margin-bottom: 0.4rem;
  margin-top: 0.4rem;
  font-size: 1.3rem;
}

.modal-container-item .item-card-container .item-card .item-card-front  .item-info .item-header h4{
  padding: 0rem;
  margin-left: 0.4rem;
  margin-right: 0.4rem;
  margin-bottom: 0;
  margin-top: 0;
}

.modal-container-item .item-card-container .item-card .item-card-front  .item-info .item-header h3{
  padding: 0rem;
  margin-left: 0.4rem;
  margin-right: 0.4rem;
  margin-bottom: 0;
  margin-top: 0;
}

.modal-container-item .item-card-container .item-card .item-card-front .item-info .item-body {
  display: flex;
  flex-direction: row;
  font-size: 1.1rem;
  align-self: center;
}

.modal-container-item .item-card-container .item-card .item-card-front .item-info .item-footer {
  
  bottom: 5rem;
  color: #d4af37;
  background-color: #2c2c2c;
  border: .2rem solid #5c4033;
  border-radius: .625rem;

  position: fixed;
  
  text-align: center;
  width: fit-content;
  align-self: center;
  padding: 0;
}

.modal-container-item .item-card-container .item-card .item-card-front .item-info .item-footer h4 {
  padding: 0rem;
  margin-left: 0.4rem;
  margin-right: 0.4rem;
  margin-bottom: 0.4rem;
  margin-top: 0.4rem;
}

.modal-container-item .item-card-container .item-card .item-card-front .item-info .item-body .item-stats-right {
  font-size: 1rem;
  margin-top: 0.5rem;
  height: 24.5rem;
  padding: 1rem;
}

.modal-container-item .item-card-container .item-card .item-card-front .item-info .item-body .item-stats-right .item-stats-right-top {
  color: #d4af37;
  background-color: #2c2c2c;
  border: .2rem solid #5c4033;
  border-radius: .625rem;
  font-size: 1rem;
  margin: 0;
  padding: 0;
}

.modal-container-item .item-card-container .item-card .item-card-front .item-info .item-body .item-stats-right .item-stats-right-top p {
  padding: 0;
  margin-left: 0.4rem;
  margin-right: 0.4rem;
  margin-bottom: 0.4rem;
  margin-top: 0.4rem;
}


.modal-container-item .item-card-container .item-card .item-card-front .item-info .item-body .item-stats-right .item-stats-right-bottom {
  color: #d4af37;
  background-color: #2c2c2c;
  border: .2rem solid #5c4033;
  border-radius: .625rem;
  font-size: 1rem;
  margin: 0;
  padding: 0;
  margin-top: 20rem;
}

.modal-container-item .item-card-container .item-card .item-card-front .item-info .item-body .item-stats-right .item-stats-right-bottom p {
  padding: 0;
  margin-left: 0.4rem;
  margin-right: 0.4rem;
  margin-bottom: 0.4rem;
  margin-top: 0.4rem;
}


.modal-container-item .item-card-container .item-card .item-card-front .item-info .item-body .item-stats-left {
  font-size: 1rem;
  margin-top: 0.5rem;
  height: 24.5rem;
  padding: 1rem;
  margin-right: 6rem;
}

.modal-container-item .item-card-container .item-card .item-card-front .item-info .item-body .item-stats-left .item-stats-left-top {
  color: #d4af37;
  background-color: #2c2c2c;
  border: .2rem solid #5c4033;
  border-radius: .625rem;
  font-size: 1rem;
  margin: 0;
  padding: 0;
}

.modal-container-item .item-card-container .item-card .item-card-front .item-info .item-body .item-stats-left .item-stats-left-top p {
  padding: 0;
  margin-left: 0.4rem;
  margin-right: 0.4rem;
  margin-bottom: 0.4rem;
  margin-top: 0.4rem;
}


.modal-container-item .item-card-container .item-card .item-card-front .item-info .item-body .item-stats-left .item-stats-left-bottom {
  color: #d4af37;
  background-color: #2c2c2c;
  border: .2rem solid #5c4033;
  border-radius: .625rem;
  font-size: 1rem;
  margin: 0;
  padding: 0;
  margin-top: 20rem;
}

.modal-container-item .item-card-container .item-card .item-card-front .item-info .item-body .item-stats-left .item-stats-left-bottom p{
  padding: 0;
  margin-left: 0.4rem;
  margin-right: 0.4rem;
  margin-bottom: 0.4rem;
  margin-top: 0.4rem;
}

.item-card-container .item-actions {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 1.2rem;
  position: fixed;
  top: 34.3rem;
}

.item-card-container .item-actions .item-buttons {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;

}

.item-buttons button {
  margin-right: 0.2;
  margin-left: 0.2;
  margin-top: 0.6rem;
  margin-bottom: 0.1rem;
  
}

.item-buttons .bp_mint_item{
  margin-right: 1rem;
}

/*******************************************************************************/
/* CentralLoading.css */

/*******************************************************************************/


.central-loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle, rgba(46,46,46,0.9) 0%, rgba(22,22,22,0.9) 80%, rgba(12,12,12,0.95) 100%);
  border: 0.2rem solid #5C4033; /* Bordure marron pour rappeler le métal vieilli */
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2000;
  color: #D4AF37; /* Couleur dorée pour le texte */
}

.central-spinner {
  width: 150px;
  height: 150px;
  background: url('https://battle-of-angelus.ovh/images/leaf-spinner.png') center/contain no-repeat; /* Utilisation de votre image */
  animation: spin 3s linear infinite; /* Animation pour faire tourner l'image */
}

.central-loading-overlay h4 {
  margin-top: 1rem;
  font-family: 'Pirata One', serif; /* Style typographique rustique */
  color: #D4AF37; /* Doré pour rappeler la rareté de ressources */
  text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.9);
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


/* BuyAngelus */
.modalBuyAngelus  {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #2c2c2c;
  padding: 20px;
  border-radius: 8px;
  z-index: 1000;
  width: 400px;
  color: #D4AF37;
}

.modalBuyAngelus .modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modalBuyAngelus .modal-content .form-group {
  margin-bottom: 15px;
}

.modalBuyAngelus .modal-content .form-group .buy-button, .modalBuyAngelus .modal-content .form-group .close-button {
  background-color: #5C4033;
  color: #D4AF37;;
  border: none;
  padding: 10px 20px;
  margin: 5px;
  cursor: pointer;
  font-size: 16px;
}

.modalBuyAngelus .modal-content .form-group .buy-button:hover, .modalBuyAngelus .modal-content .form-group .close-button:hover {
  background-color: #4caf50;
}

.modalBuyAngelus .modal-content .error-message {
  color: red;
  margin-top: 10px;
} 



/* Inventaire */
.modal-container-detail .modal-container-inventory .modal-container-item {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  padding: 1.5rem;
  max-width: 600px;
  width: 90%;
  height: auto;
  background-color: rgba(44, 44, 44, 0.9);
  border: 2px solid #d3913c;
  border-radius: 8px;
  color: #D4AF37;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.8);
}



.modal-container-detail .modal-container-inventory .modal-container-item .item-display .item-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.1);
  border: 2px solid #d3913c;
  border-radius: 8px;
  width: 100%;
  padding: 1rem;
}

.modal-container-detail .modal-container-inventory .modal-container-item .item-display .item-card h4 {
  margin: 0;
  font-size: 1.5rem;
  color: #D4AF37;
}
.modal-container-detail .modal-container-inventory .modal-container-item .item-display .item-card h2 {
  font-family: 'MedievalSharp', cursive;
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #D4AF37;
}
.modal-container-detail .modal-container-inventory .modal-container-item .item-display .item-card img {
  width: 100px;
  height: 100px;
  margin-bottom: 1rem;
}



.modal-container-detail .modal-container-inventory .modal-container-item .item-display .item-card p {
  margin: 0;
  color: #D4AF37;
}

.modal-container-detail .modal-container-inventory .modal-container-item .item-display .item-card .item-actions {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-top: 1.5rem;
}

.modal-container-detail .modal-container-inventory .modal-container-item .item-display .item-card .item-actions button {
  background-color: #2C2C2C;
  border: 2px solid #5C4033;
  color: #D4AF37;
  font-family: 'Pirata One', serif;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.modal-container-detail .modal-container-inventory .modal-container-item .item-display .item-card .item-actions button:hover {
  background-color: #3F3F3F;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.7);
  color: #FFFFFF;
}

.modal-container-detail .modal-container-inventory .modal-container-item  .item-navigation {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 1.5rem;
}

.modal-container-detail .modal-container-inventory .modal-container-item  .item-navigation button {
  background-color: #2C2C2C;
  border: 2px solid #5C4033;
  color: #D4AF37;
  font-family: 'Pirata One', serif;
  padding: 0.5rem 1.5rem;
  cursor: pointer;
  border-radius: 8px;
  transition: background-color 0.3s;
}

.modal-container-detail .modal-container-inventory .modal-container-item  .item-navigation button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.modal-container-detail .modal-container-inventory .modal-container-item  .item-navigation button:hover:not(:disabled) {
  background-color: #3F3F3F;
}


.modal-container-detail .modal-container-inventory .modal-container-item .item-display .item-card .item-image-container {
  position: relative;
  display: inline-block;
}

.modal-container-detail .modal-container-inventory .modal-container-item .item-display .item-card .item-image-container img {
  width: 100%;
  height: auto;
}


.modal-container-detail .modal-container-inventory .modal-container-item .item-display .item-card .item-image-container  .item-attributes-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); /* Couleur de fond avec transparence */
  color: #D4AF37; /* Texte doré pour les attributs */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 0.9rem;
  opacity: 1; /* Toujours visible si showAttributes est true */
}



.modal-container-item .item-display .item-card .item-name-rarity {
  /* Style par défaut */
  font-size: 1.5rem; /* Ajustez selon vos besoins */
}
/* Styles pour les raretés */
.modal-container-item .item-display .item-card .item-name-rarity.common {
  color: #d4af37; /* Couleur pour commun (noir) */
}

.modal-container-item .item-display .item-card .item-name-rarity.rare {
  color: #007bff; /* Couleur pour rare (bleu) */
  font-weight: bold; /* Plus épais pour plus d'importance */
}

.modal-container-item .item-display .item-card .item-name-rarity.epic {
  color: #ff5733; /* Couleur pour épique (orange ou une autre couleur) */
  font-weight: bold; /* Plus épais pour plus d'importance */
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5); /* Ombre pour donner plus d'impact */
}



.modal-container-detail .modal-container-inventory .modal-container-item .item-display .item-card .item-image-container .item-attributes-overlay p {
  margin: 0.2rem 0; /* Espacement entre les attributs */
}


.armor-image, .weapon-image {
  height: 8.2rem;
  width: 6.7rem;
  object-fit: cover;
}

.empty-slot {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 8.2rem;
  width: 6.7rem;
  object-fit: cover;
  background-color: #2c2c2cbd;
  border: 2px dashed #ccc;
}

.icon-plus {
  font-size: 5rem;
  color: #dd1111;
  font-style: normal;
}

.armure-content, .arme-content {
  text-align: center;
}

.armorDetailsHaut{
  display: inline;
  top: -8.2rem;
  position: relative;
  background-color: #2c2c2c;
  border: .2rem solid #5c4033;
  border-radius: .625rem;
  color: #d4af37;
}

.armorDetailsBas{
  display: inline;
  top: -3rem;
  position: relative;
  background-color: #2c2c2c;
  border: .2rem solid #5c4033;
  border-radius: .625rem;
  color: #d4af37;
}

.select-button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  transition: opacity 0.3s ease;
}

.select-button.disabled {
  background-color: #ccc;
  cursor: not-allowed;
  opacity: 0.8;
}

.select-button:hover:not(.disabled) {
  background-color: #0056b3;
}

.tooltip {
  margin-top: 5px;
  color: red;
  font-size: 12px;
}

.select-perso.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}


/* Le fond du modal */
.modal-market {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0,1); /* Fond semi-transparent */
}

/* Contenu du modal */
.modal-market-content {
  background-color: #2C2C2C;
  margin: 5% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-height: 80%;
  overflow-y: auto;
  position: relative;
  top: 4rem
}

/* Bouton de fermeture */
.close-market {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.close-market:hover,
.close-market:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.img-market-vente{
  width: 5rem;
}


/*******************************************************************************/



/* Arena */
/*.arena-section {
  margin: 1.25em 0; 
  padding: 0.625em; 
  border: 0.125em solid #d3913c; 
  border-radius: 0.3125em; 
  background: rgba(255, 255, 255, 0.2);
}*/
.arena-section {
  margin: 20px;
  width: 100%;
  max-width: 1200px;
  min-height: 400px;
  display: flex;
  justify-content: space-between;
}
/* Dé */

.dice-container canvas {
  width: 150px; /* Ajustez la largeur */
  height: 150px; /* Ajustez la hauteur */
  background-color: rgba(255, 255, 255, 0.3); /* Fond légèrement visible pour le débogage */
  border: 1px solid red; /* Ajoutez une bordure pour bien visualiser */
}

.content_arene_combat {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.arena-gauche, .arena-centre, .arena-droite {
  background-color: rgba(0, 0, 0, 0.7); /* Fond légèrement transparent */
  padding: 10px;
  border-radius: 10px;
  border: 2px solid #d3913c;
  min-height: 300px; /* Ajuste la hauteur minimale de la zone de combat */
  text-align: center;
}

.arena-gauche {
  /* Styles spécifiques à la zone gauche */
}

.arena-centre {
  /* Styles spécifiques à la zone centrale */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.arena-droite {
  /* Styles spécifiques à la zone droite */
}

.dice-section {
  text-align: center;
  margin-top: 20px;
}

.dice-container {
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 150px;
  justify-content: center;
  perspective: 1000px;
  width: 150px;
  padding: 0;
  margin-left: 3rem;

}

.dice {
  width: 100px;
  height: 100px;
  background-size: cover;
  background-position: center;
  border: 2px solid #ccc;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.dice-1 { background-image: url('https://battle-of-angelus.ovh/images/des/1.png'); }
.dice-2 { background-image: url('https://battle-of-angelus.ovh/images/des/2.png'); }
.dice-3 { background-image: url('https://battle-of-angelus.ovh/images/des/3.png'); }
.dice-4 { background-image: url('https://battle-of-angelus.ovh/images/des/4.png'); }
.dice-5 { background-image: url('https://battle-of-angelus.ovh/images/des/5.png'); }
.dice-6 { background-image: url('https://battle-of-angelus.ovh/images/des/6.png'); }

.dice-section h3 {
  margin-bottom: 10px;
}

.dice_content_centre{
  align-self: center;
}

/* src/components/Story.css */
.story-container {
  padding: 20px;
  background-color: rgba(44, 44, 44, 0.9);
  border: 2px solid #d3913c;
  border-radius: 8px;
  color: #D4AF37;
  margin: 20px;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.choices-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
}

.choices-container button {
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  background-color: #4CAF50;
  color: white;
  transition: background-color 0.3s;
}

.choices-container button:hover {
  background-color: #45a049;
}

.eco-score {
  margin-top: 20px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}

/* src/components/Combat.css */
.combat-container {
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.85);
  color: white;
  border-radius: 10px;
  margin: 20px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.combat-log {
  max-height: 300px;
  overflow-y: auto;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 20px;
  font-size: 1.1rem;
  color: #d4af37;

}

.actions-columns{
  flex-direction: column;
  display: flex;
}
.combat-actions {
  display: flex;
  flex-direction: row;
  gap: 10px;
  background-color: #2c2c2c;
  border: .2rem solid #5c4033;
  border-radius: .625rem;
  color: #d4af37;
}

.header-combat {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 1rem;
}

.health-bar {
  width: 100px;
  height: 20px;
  color: #D4AF37;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
}

.health-bar-fill {
  height: 100%;
  color: #D4AF37;
  font-weight: bold;
  font-size: 0.9rem;
  text-align: center;
  line-height: 20px;
  transition: width 0.3s ease;
  border-radius: 5px;
}

.h2-Combat{
  font-size: 1.3rem;
  color: #D4AF37;
}

.actions-buttons {
  display: flex;
  gap: 10px;
}

.combat-actions button {
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  background-color: #2196F3;
  color: white;
  transition: background-color 0.3s;
}

.combat-actions button:hover {
  background-color: #0b7dda;
}

.combat-actions button.selected {
  background-color: #0b7dda;
}

.combat-result {
  text-align: center;
}

.combat-result button {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  background-color: #f44336;
  color: white;
  transition: background-color 0.3s;
}

.combat-result button:hover {
  background-color: #da190b;
}


/* src/App.css */
.arena-combat {
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 10px;
  margin: 20px;
  min-height: 400px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.start-story-container {
  text-align: center;
}

.start-story-button {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 18px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  background-color: #4CAF50;
  color: white;
  transition: background-color 0.3s;
}

.start-story-button:hover {
  background-color: #45a049;
}


/* partie infos charactere for play */
.charactere-play-container{
  display: flex;
  flex-direction: column;
}


/* character container */
.charactere-play-container .character-container{
  display: flex;
}

.charactere-play-container .character-container .character-infos {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  font-size: 1rem;
}

.charactere-play-container .character-container .character-infos .character-name{
  margin: 0;
  padding: 0;
}

.charactere-play-container .character-container .character-infos .character-race{
  margin: 0;
  padding: 0;
}

.charactere-play-container .character-container .character-infos .character-images{
  margin: 0;
  padding: 0;
}

.charactere-play-container .character-container .character-infos .character-images .img_personnage_front{
  height: 9rem;
  margin: 0;
  padding: 0;
  width: 7rem;
}

.charactere-play-container .character-container .character-infos  .character-attributes{
  margin: 0;
  padding: 0;
}

.charactere-play-container .character-container .character-infos .character-taille{
  margin: 0;
  padding: 0;
}

.charactere-play-container .character-container .character-infos .img_personnage{
  margin: 0;
  padding: 0;
  width: 2rem;
  height: 3rem;
}


/* Armure Container */
.charactere-play-container .armure-container  {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
}








/* Arme Container */
.charactere-play-container .arme-container{
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
}





/* Structure de la carte pour le flip */
.character-card {
  width: 300px; /* Ajustez la largeur selon vos besoins */
  height: 32rem; /* Ajustez la hauteur selon vos besoins */
  perspective: 1000px; /* Nécessaire pour l'effet de flip */
  margin: 1rem;
  cursor: pointer;
}

.character-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.character-card.flipped .character-card-inner {
  transform: rotateY(180deg);

}

.character-card-front,
.character-card-back {
  position: absolute;
  width: 100%;
  height: 32rem;
  backface-visibility: hidden;
  border: 0.2rem solid #5C4033; /* Bordure marron */
  border-radius: 0.625rem; /* Coins arrondis */
  background-color: #2C2C2C; /* Couleur de fond sombre, métallique */
  color: #D4AF37; /* Couleur dorée pour le texte */
  padding: 1rem;
  box-sizing: border-box;
}

/* Face Arrière doit être retournée */
.character-card-back {
  transform: rotateY(180deg);
  overflow-y: auto; /* Permet de faire défiler si le contenu est trop grand */
  font-size: 1rem;
}

/* Ajoutez des styles pour les sections supplémentaires si nécessaire */
.character-class-actions {
  /* Styles pour les actions de classe */
}

.actions-section {
  margin-bottom: 1rem;
}

.actions-section h4 {
  margin-bottom: 0.5rem;
}

/* Animation de rotation supplémentaire pour un effet plus fluide */
@keyframes flip {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(180deg);
  }
}

/* Optionnel : Styles pour les identifiers */
.character-identifiers p {
  margin: 0.5rem 0;
}

/* Responsive Design */
@media (max-width: 600px) {
  .character-card {
    width: 90%;
    height: auto;
  }
}

.armure-infos{
  backface-visibility: hidden;
  background-color: #2c2c2c;
  border: .2rem solid #5c4033;
  border-radius: .625rem;
  box-sizing: border-box;
  color: #d4af37;
  height: 32rem;
  
  position: relative;
  width: 300px;
  height: fit-content;
  font-size: 1rem;
  margin: 0;
  padding: 0;
  align-self: center;

}

.arme-infos{
  backface-visibility: hidden;
  background-color: #2c2c2c;
  border: .2rem solid #5c4033;
  border-radius: .625rem;
  box-sizing: border-box;
  color: #d4af37;
  height: 32rem;
  
  position: relative;
  width: 300px;
  height: fit-content;
  font-size: 1rem;
  margin: 0;
  padding: 0;
  align-self: center;

}

.item-name-rarity {
  /* Style par défaut */
  font-size: 1.5rem; /* Ajustez selon vos besoins */
}

/* Styles pour les raretés */
.item-name-rarity.common {
  color: #d4af37; /* Couleur pour commun (noir) */
}

.item-name-rarity.rare {
  color: #007bff; /* Couleur pour rare (bleu) */
  font-weight: bold; /* Plus épais pour plus d'importance */
}

.item-name-rarity.epic {
  color: #ff5733; /* Couleur pour épique (orange ou une autre couleur) */
  font-weight: bold; /* Plus épais pour plus d'importance */
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5); /* Ombre pour donner plus d'impact */
}


.action-button {
  background-color: #2C2C2C; /* Couleur de fond sombre, métallique */
  border: 0.2rem solid #5C4033; /* Bordure marron */
  color: #D4AF37; /* Texte doré */
  padding: 0.5rem 1rem;
  border-radius: 0.3rem;
  cursor: pointer;
  margin-bottom: 0.5rem;
  font-family: 'Pirata One', serif; /* Assure que la police est bien chargée */
  font-size: 1rem;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.action-button:hover {
  background-color: #3F3F3F; /* Variation de la couleur de fond au survol */
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.7); /* Ombre plus prononcée au survol */
  color: #FFFFFF; /* Texte devient blanc */
}



/* ENEMY */

.enemy-details{
  /*position: absolute;*/
  /*width: 100%;*/
  /*height: 32rem;*/
  /*backface-visibility: hidden;*/
  border: 0.2rem solid #5C4033; /* Bordure marron */
  border-radius: 0.625rem; /* Coins arrondis */
  background-color: #2C2C2C; /* Couleur de fond sombre, métallique */
  color: #D4AF37; /* Couleur dorée pour le texte */
  padding: 1rem;
  box-sizing: border-box;
  font-size: 1rem;
}

.enemy-image{
  height: 9rem;
  margin: 0;
  padding: 0;
  width: 7rem;
}

.enemy-details .p_nopadding{
  margin: 0;
  padding: 0;
}

.enemy-details .p_nopadding_bottom{
  margin: 0;
  padding: 0;
  padding-top: 1rem;
}






.accordion-container {
  margin-bottom: 1rem;
  border: 0.2rem solid #5C4033;
  border-radius: 0.625rem;
  background-color: #2C2C2C;
  color: #D4AF37;
  cursor: pointer;
}

.accordion-title {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  font-size: 1.2rem;
}

.accordion-content {
  padding: 1rem;
  background-color: #3C3C3C;
}


.action-button {
  background-color: #2C2C2C;
  border: 0.2rem solid #5C4033;
  color: #D4AF37;
  padding: 0.5rem 1rem;
  border-radius: 0.3rem;
  cursor: pointer;
  font-family: 'Pirata One', serif;
  font-size: 1rem;
  transition: background-color 0.3s, box-shadow 0.3s;
  margin-bottom: 0.5rem;
}

.action-button:hover {
  background-color: #3F3F3F;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.7);
  color: #FFFFFF;
}

.action-button.selected {
  background-color: #0b7dda;
}

.turn-indicator {
  text-align: center;
  margin-top: 20px;
  font-size: 1.2rem;
  color: #D4AF37; /* Ou une autre couleur qui se démarque */
}

.marketplace-button {
  display: flex;
  gap: 1rem; /* Espace entre les boutons */
}








/* Modal Staking Styles */

/* Le fond du modal staking */
.modal-staking {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.8); /* Fond semi-transparent */
}

/* Contenu du modal staking */
.modal-staking-content {
  background-color: #2C2C2C; /* Même couleur que le modal du marché */
  margin: 5% auto;
  padding: 20px;
  border: 0.2rem solid #5C4033; /* Bordure marron pour rappeler le cuir ou le métal vieilli */
  width: 80%;
  max-width: 600px;
  max-height: 80%;
  overflow-y: auto;
  position: relative;
  top: 4rem;
  color: #D4AF37; /* Couleur dorée pour le texte */
  border-radius: 0.625rem; /* Coins arrondis */
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.8);
}

/* Bouton de fermeture du modal staking */
.close-staking {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.close-staking:hover,
.close-staking:focus {
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}

/* Styles pour les titres et les paragraphes */
.modal-staking-content h2,
.modal-staking-content h3,
.modal-staking-content p {
  font-family: 'Pirata One', serif;
  color: #D4AF37;
}

/* Styles pour les boutons à l'intérieur du modal */
.modal-staking-content button {
  background-color: #2C2C2C;
  border: 0.2rem solid #5C4033;
  color: #D4AF37;
  cursor: pointer;
  font-size: 1rem;
  font-family: 'Pirata One', serif;
  border-radius: 0.3rem;
  box-shadow: 0 0.3rem 0.5rem rgba(0, 0, 0, 0.5);
  transition: background-color 0.3s, box-shadow 0.3s;
  margin: 0.5rem 0;
  padding: 0.5rem 1rem;
}

.modal-staking-content button:hover {
  background-color: #3F3F3F;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.7);
  color: #FFFFFF;
}

/* Styles pour les inputs et les selects */
.modal-staking-content input,
.modal-staking-content select {
  background-color: #1C1C1C;
  border: 0.1rem solid #5C4033;
  color: #D4AF37;
  padding: 0.5rem;
  font-size: 1rem;
  font-family: 'Pirata One', serif;
  border-radius: 0.3rem;
  margin: 0.5rem 0;
  width: 100%;
  box-sizing: border-box;
}

/* Styles pour le conteneur du contenu */
.modal-staking-content .staking-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}



.listings{
  display: flex;
  flex-direction: row;
  margin-left: 2rem;
}


.listing{
  margin: 2rem;
}